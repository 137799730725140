<template>
    <div>
        <b-overlay :show="loading">
            <b-table-simple responsive hover bordered>
                <b-thead>
                    <b-tr>
                        <b-th class="text-center" width="20%">{{ $t('globalTrans.sl_no') }}</b-th>
                        <b-th class="text-center">{{ $t('bazarMonitoring.market_name') }}</b-th>
                        <b-th class="text-center" width="20%">{{ $t('globalTrans.price') }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <template v-if="listData.length">
                        <b-tr v-for="(item, index) in listData" :key="index">
                            <b-td class="text-center">{{ $n(index + 1, { useGrouping: false }) }}</b-td>
                            <b-td class="text-left">{{ getMarketDirectoryName(item.market_directory_id) }}</b-td>
                            <b-td class="text-center">{{ priceType === 1 ? $n(item.lowest_price_amount) : $n(item.highest_price_amount) }}</b-td>
                        </b-tr>
                    </template>
                    <b-tr v-else>
                        <b-td class="text-center" colspan="3">{{ $t('globalTrans.noDataFound') }}</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-overlay>
    </div>
</template>
<script>
import RestApi, { bazarMonitoringServiceBaseUrl } from '@/config/api_config'
import { marketDirectoryList } from '../../api/routes'
export default {
    name: 'Details',
    props: ['search', 'priceType'],
    data () {
        return {
            listData: []
        }
    },
    created () {
        this.loadData()
    },
    computed: {
        loading: function () {
            return this.$store.state.commonObj.loading
        },
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        async loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const result = await RestApi.getData(bazarMonitoringServiceBaseUrl, marketDirectoryList, this.search)
            this.listData = result.data
            this.$store.dispatch('mutateCommonProperties', { loading: false })
        },
        getMarketDirectoryName (id) {
            const obj = this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.find(item => item.value === id)
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        }
    }
}
</script>
